<template>
  <div class="map-loader">
    <div class="google-map" ref="googleMap"></div>
    <template v-if="Boolean(this.google) && Boolean(this.map)">
      <slot :google="google" :map="map" />
    </template>
  </div>
</template>

<script>
/*
  generic loader for Google Maps
  https://vuejs.org/v2/cookbook/practical-use-of-scoped-slots.html
*/
import { Loader } from '@googlemaps/js-api-loader'

const LoadedEventName = 'loaded'

export default {
  props: {
    apiKey: {
      type: String,
      required: true,
    },
    mapConfig: {
      type: Object,
      required: true,
    },
    mapIds: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      google: null,
      map: null,
    }
  },

  methods: {
    _initializeMap() {
      const mapContainer = this.$refs.googleMap
      this.map = new google.maps.Map(mapContainer, this.mapConfig)

      // save global var 'google' to pass around app
      this.google = window.google
    },

    _emitLoadedEvent() {
      const event = {
        google: google,
        map: this.map,
      }
      this.$emit(LoadedEventName, event)
    },

    _loadGoogleMapsApi() {
      const loader = new Loader({
        apiKey: this.apiKey,
        mapIds: this.mapIds,
      })
      loader
        .load()
        .then(() => this._initializeMap())
        .then(() => this._emitLoadedEvent())
        .catch(e => {
          console.error('error loading maps', e)
        })
    },
  },

  async mounted() {
    if (window.google == undefined) {
      this._loadGoogleMapsApi()
    } else {
      this._initializeMap()
    }
  },
}
</script>

<style lang="scss">
.map-loader {
  height: 100%;

  .google-map {
    height: 100%;
    width: 100%;
    background: '#ddd';
  }
}
</style>
