/*
  Application Constants
*/

// approximate center of USA
export const CenterUSAGps = { lat: 39.8333333, lng: -98.585522 }

const siteOrigin = window.location.origin
export const EmbedUrl = `${siteOrigin}/embed`

/*
  breakpoints
  used to determine map zoom level onLoad
*/
export const DeviceBreakPoints = {
  mobile: 400,
  tablet: 768,
  desktop: 1024,
}

/*
  default map zoom-level for device
*/
export const MapZoom = {
  mobile: 3,
  tablet: 4,
  desktop: 4.5,
}

/*
  value to use when adjusting map bounds
*/
export const MapCoordinatesOffset = 0.1
