//* eslint-disable */

// THIS FILE IS AUTOMATICALLY GENERATED - DO NOT EDIT!

export const mapRecords = [
  {
    "index": 0,
    "articleUrl": "",
    "city": "Plano",
    "fullName": "Jacqueline",
    "latitude": 33.05370230018799,
    "longitude": -96.75380305808693,
    "numChildren": "1",
    "quote": "\"The standard of support is so low for so many single moms raising children. Many are living hand to mouth and do not have affordable health care or a support system. The USA gives millions of dollars in aid to everywhere else in the world but does not take care of US citizens.\"",
    "state": "Texas",
    "imageSqFull": "images/attIGEtNE8kctzjL4-full.png",
    "imageSqLarge": "images/attIGEtNE8kctzjL4-large.png"
  },
  {
    "index": 1,
    "articleUrl": "",
    "city": "Seattle",
    "fullName": "Sieglinde",
    "latitude": 47.61502615637753,
    "longitude": -122.30842622753157,
    "numChildren": "1",
    "quote": "\"It is inexcusable that it took a massive, global pandemic to prioritize the health and welfare of our children and families with a Child Tax Credit. It is unconscionable that it is even on the table now to take it away.\"",
    "state": "Washington",
    "imageSqFull": "images/attQlPWNcZ1bd0eNW-full.png",
    "imageSqLarge": "images/attQlPWNcZ1bd0eNW-large.png"
  },
  {
    "index": 2,
    "articleUrl": "",
    "city": "Bronx",
    "fullName": "Tamica",
    "latitude": 40.86851661008025,
    "longitude": -73.85604286313783,
    "numChildren": "1",
    "quote": "\"How the Child Tax Credit has helped me and my son is by paying bills that I have been behind on and home essentials I need for my home. Also, it has helped in buying clothes and shoes for my son or I. I am sooo appreciate and grateful for it because I reallyyyyy needed more money.\"",
    "state": "New York",
    "imageSqFull": "images/attMoIG63orxtDt7J-full.png",
    "imageSqLarge": "images/attMoIG63orxtDt7J-large.png"
  },
  {
    "index": 3,
    "articleUrl": "",
    "city": "Wilmington",
    "fullName": "Elena",
    "latitude": 39.441294979122375,
    "longitude": -83.84825529935236,
    "numChildren": "1",
    "quote": "\"The Child Tax Credit is making a significant difference in our fall season/semester. I have a way to pay for school and sport fees, graphing calculator, and transportation - as soon as my brakes are fixed ($700), I may be hired back at the YMCA!\"",
    "state": "Ohio",
    "imageSqFull": "images/attNDBOr5OGPg8H4M-full.png",
    "imageSqLarge": "images/attNDBOr5OGPg8H4M-large.png"
  },
  {
    "index": 4,
    "articleUrl": "",
    "city": "Portland",
    "fullName": "Rae",
    "latitude": 45.623843669795676,
    "longitude": -122.76701969701267,
    "numChildren": "3",
    "quote": "\"We are a middle class family. The CTC has given us a little extra financial room to spend more time with our kids, and stress less about money, and that is good for our whole household’s mental and physical health. Less stressed families make healthier communities. Healthier communities make stronger countries. It’s a win/win for all of us!\"",
    "state": "Oregon",
    "imageSqFull": "images/attZAgI7JwxTH39mq-full.png",
    "imageSqLarge": "images/attZAgI7JwxTH39mq-large.png"
  },
  {
    "index": 5,
    "articleUrl": "",
    "city": "Atlanta",
    "fullName": "Carrie-Anne",
    "latitude": 33.663683879617004,
    "longitude": -84.3656814855189,
    "numChildren": "2",
    "quote": "\"As a single mom who doesn't receive child support, the CTC has allowed me to enroll my daughter in both Girl Scouts and gymnastics, programs that will enrich her life and I couldn't afford otherwise.\"",
    "state": "Georgia",
    "imageSqFull": "images/attQJTMkFGX3BrmXA-full.png",
    "imageSqLarge": "images/attQJTMkFGX3BrmXA-large.png"
  },
  {
    "index": 6,
    "articleUrl": "",
    "city": "Washington",
    "fullName": "Erin",
    "latitude": 38.93507665611463,
    "longitude": -77.0090914019802,
    "numChildren": "1",
    "quote": "\"Even for two working parents, having an infant is expensive! We also care for my elderly father, so the Child Tax Credit really helps us feel a little more secure financially, which takes off some of the stress of new parenthood so we can really just focus on loving our baby.\" ",
    "state": "D.C.",
    "imageSqFull": "images/attS2fjeMT2DKq7NK-full.png",
    "imageSqLarge": "images/attS2fjeMT2DKq7NK-large.png"
  },
  {
    "index": 7,
    "articleUrl": "https://www.businessinsider.com/personal-finance/families-affected-child-tax-credit-payments-stopped-2022-3",
    "city": "Silverton",
    "fullName": "Emily-Fern",
    "latitude": 44.94576,
    "longitude": -122.64353,
    "numChildren": "1",
    "quote": "\"The Child Tax Credit will be a game changer for families like ours who gave up our careers in order to adequately care for our children who have disabilities and chronic, complex medical conditions.\"",
    "state": "Oregon",
    "imageSqFull": "images/attcbdaPtrXkOHulW-full.png",
    "imageSqLarge": "images/attcbdaPtrXkOHulW-large.png"
  },
  {
    "index": 8,
    "articleUrl": "",
    "city": "Los Angeles",
    "fullName": "Sarah",
    "latitude": 34.08729093443899,
    "longitude": -118.30466098657925,
    "numChildren": "3",
    "quote": "\"The CTC should be permanent because it would help alleviate the bills for the children’s needs and ensure a better future for them.\"",
    "state": "California",
    "imageSqFull": "images/attBQfUuCrtAFY8GO-full.png",
    "imageSqLarge": "images/attBQfUuCrtAFY8GO-large.png"
  },
  {
    "index": 9,
    "articleUrl": "",
    "city": "Cambridge",
    "fullName": "Melissa",
    "latitude": 42.370395615532686,
    "longitude": -71.10295392495536,
    "numChildren": "3",
    "quote": "\"There are far too many kids living in poverty in the US, many in deep poverty. A permanent child tax credit would go a long way towards reducing child poverty and investing in all of our children. It means kids won’t go to school hungry, will have a winter coat when they need it, and sneakers that are the right size.\"",
    "state": "Massachusetts",
    "imageSqFull": "images/attalEwNFFiof8hhS-full.png",
    "imageSqLarge": "images/attalEwNFFiof8hhS-large.png"
  },
  {
    "index": 10,
    "articleUrl": "",
    "city": "St. Louis",
    "fullName": "Toni",
    "latitude": 38.627003,
    "longitude": -90.199402,
    "numChildren": "2",
    "quote": "\"The Child Tax Credit is imperative to lifting children out of poverty, and giving them a positive start that will lead to better future. The tax credit has really helped us afford utilities and other essentials.\"",
    "state": "Missouri",
    "imageSqFull": "images/attl6pfQV1pmF0REb-full.png",
    "imageSqLarge": "images/attl6pfQV1pmF0REb-large.png"
  },
  {
    "index": 11,
    "articleUrl": "",
    "city": "Cleveland",
    "fullName": "Latha",
    "latitude": 41.4732710158354,
    "longitude": -81.71822984591354,
    "numChildren": "2",
    "quote": "\"I support the CTC as an extension of my advocacy for a national Universal Basic Income. A citizen’s worth shouldn’t be tied to whether they are able to or choose to work. A CTC is a kind of UBI for parents and does a lot to lift parents, especially women, up, and also helps reduce poverty.\"",
    "state": "Ohio",
    "imageSqFull": "images/attj1atlXrYcDwMTJ-full.png",
    "imageSqLarge": "images/attj1atlXrYcDwMTJ-large.png"
  },
  {
    "index": 12,
    "articleUrl": "https://time.com/6112062/child-tax-credit-supports-my-special-needs-sons/",
    "city": "Raleigh",
    "fullName": "Jennifer",
    "latitude": 35.94882,
    "longitude": -78.61332,
    "numChildren": "2",
    "quote": "\"The expanded Child Tax Credit must be made permanent; lifting that many children out of poverty only to slam them back into it several months later would be an act of extreme cruelty, and an utterly shameful moment in the history of our country.....I have two children with special needs and chronic health conditions. We're using the child tax credit payments for medical expenses - copays, an upcoming MRI for my son that will cost us $800 out-of-pocket, an upcoming trip for him to his specialist out of state, etc.\"",
    "state": "North Carolina",
    "imageSqFull": "images/attKoBkR9y49vNRTA-full.png",
    "imageSqLarge": "images/attKoBkR9y49vNRTA-large.png"
  },
  {
    "index": 13,
    "articleUrl": "https://www.nytimes.com/2021/12/21/well/family/child-tax-credit-families.html",
    "city": "Las Vegas",
    "fullName": "Maria",
    "latitude": 36.17391,
    "longitude": -115.05291,
    "numChildren": "5+",
    "quote": "\"The Child Tax Credit has been a lifeline to my family. It has allowed us to breathe a sigh of relief, and sleep a little better.\"",
    "state": "Nevada",
    "imageSqFull": "images/att43Pt5PTcX3nJ2B-full.png",
    "imageSqLarge": "images/att43Pt5PTcX3nJ2B-large.png"
  },
  {
    "index": 14,
    "articleUrl": "",
    "city": "Bruneau",
    "fullName": "Christine",
    "latitude": 42.49895,
    "longitude": -115.50417,
    "numChildren": "2",
    "quote": "\"My youngest daughter has Enamel Hypoplasia which causes her teeth the erode extremely easily and the treatment is very expensive. The CTC will allow us to take her to specialist who can really help her.\"",
    "state": "Idaho",
    "imageSqFull": "images/attgD9Gt775BNPlZC-full.png",
    "imageSqLarge": "images/attgD9Gt775BNPlZC-large.png"
  },
  {
    "index": 15,
    "articleUrl": "https://www.montanarightnow.com/montana/parents-worry-about-making-ends-meet-without-child-tax-credits/article_94275ff0-7711-11ec-8b93-435adc5128dc.html",
    "city": "Bozeman",
    "fullName": "Jenn",
    "latitude": 45.677206291411075,
    "longitude": -111.05056112591396,
    "numChildren": "1",
    "quote": "\"If we really believe as a society that parenting is important, we should stand behind that belief with concrete assistance to parents. Let’s not continue to push parents to the breaking point; let’s support them in providing a loving and supportive home environment for the good of our entire society, both economically and because it’s the right thing to do.\"",
    "state": "Montana",
    "imageSqFull": "images/attFf42baOUEPtHEz-full.png",
    "imageSqLarge": "images/attFf42baOUEPtHEz-large.png"
  },
  {
    "index": 16,
    "articleUrl": "",
    "city": "Travelers Rest",
    "fullName": "Amy-Lyn",
    "latitude": 34.965302374792074,
    "longitude": -82.43547700848167,
    "numChildren": "1",
    "quote": "\"This has been a such a relief for my family. Every 30 days we have to balance paying for bills, groceries, medicines, and the various endlessly recurring costs of life. The monthly Child Tax Credit is like a breath of fresh air. Maybe it can pay for dinners one month, birthday presents the next, or a doctor's visit the next. It goes right back into the community, which ends up helping everybody!\"",
    "state": "South Carolina",
    "imageSqFull": "images/atth8iFgPbuLPKaPK-full.png",
    "imageSqLarge": "images/atth8iFgPbuLPKaPK-large.png"
  },
  {
    "index": 17,
    "articleUrl": "",
    "city": "Grants Pass",
    "fullName": "Julia",
    "latitude": 42.431826811529625,
    "longitude": -123.31084026626263,
    "numChildren": "1",
    "quote": "\"This money is necessary to be a fully independent mama that I know I have the potential of being.\"",
    "state": "Oregon",
    "imageSqFull": "images/att4LA5RkEioVNVPF-full.png",
    "imageSqLarge": "images/att4LA5RkEioVNVPF-large.png"
  },
  {
    "index": 18,
    "articleUrl": "",
    "city": "Lebanon",
    "fullName": "Courtney",
    "latitude": 43.627187626325465,
    "longitude": -72.25379925114937,
    "numChildren": "1",
    "quote": "\"This extra money each month can lift unseen burdens. It's not much, but it can buy a pair of winter boots, a Halloween costume, and maybe a little extra peace of mind, if only temporarily.\"",
    "state": "New Hampshire",
    "imageSqFull": "images/attOeZnPuaUgoZCPE-full.png",
    "imageSqLarge": "images/attOeZnPuaUgoZCPE-large.png"
  },
  {
    "index": 19,
    "articleUrl": "",
    "city": "Wilmington",
    "fullName": "Lauren",
    "latitude": 39.74756923003695,
    "longitude": -75.55077257954976,
    "numChildren": "1",
    "quote": "\"My husband and I were shocked at how much infant care would cost us when our son was born - more than my annual college tuition was. To offset those costs, I went from full-time to part time and we utilized our parents' help. The Child Tax Credit back then would have meant we could afford full time care, and moving forward it would mean we are able to have more children and ensure their safety and well being.\"",
    "state": "Delaware",
    "imageSqFull": "images/attUcSJoKnoBPORFK-full.png",
    "imageSqLarge": "images/attUcSJoKnoBPORFK-large.png"
  },
  {
    "index": 20,
    "articleUrl": "",
    "city": "Amherst",
    "fullName": "Victoria",
    "latitude": 44.448041454779805,
    "longitude": -89.28628785966812,
    "numChildren": "3",
    "quote": "\"This is the first year we've been able to pay all our bills and still have money left over at the end of the month. Every other year, we've had to let some things go unpaid or fallen behind, doing our best to catch up on the heating bill we couldn't afford before it gets shut off in spring, trying to scrape together enough to put a nice Thanksgiving and Christmas dinner on the table, find some way to pay the hundreds of dollars of school costs and supplies, and try to fix our poor old car so it keeps running. I can finally breathe a little easier, say yes to a candy bar for the kids at the store, not find myself crying every night when I'm trying to figure out where to get the money to cover the overdraft. It's cut down in stress, depression, anxiety, and strain on the family in general. Being able to count on something when both our jobs have no guarantee of income each month has made a world of difference!\"",
    "state": "Wisconsin",
    "imageSqFull": "images/attp5XhtZBJOVX673-full.png",
    "imageSqLarge": "images/attp5XhtZBJOVX673-large.png"
  },
  {
    "index": 21,
    "articleUrl": "",
    "city": "Bushkill",
    "fullName": "Jamillah",
    "latitude": 41.10894982091203,
    "longitude": -75.0028701993217,
    "numChildren": "1",
    "quote": "\"The Child Tax Credit payments have given me a little peace of mind as far as my child's financial security every month. A two-year old costs a lot to maintain between food, diapers, clothing, vitamins, books/toys, etc. The CTC allows me to provide more.\"",
    "state": "Pennsylvania",
    "imageSqFull": "images/attCMZlTS2vxiN6yL-full.png",
    "imageSqLarge": "images/attCMZlTS2vxiN6yL-large.png"
  },
  {
    "index": 22,
    "articleUrl": "",
    "city": "New Albany",
    "fullName": "Latoya",
    "latitude": 34.511775075353405,
    "longitude": -89.01293861687753,
    "numChildren": "2",
    "quote": "\"Child Tax Credit payments have helped me out tremendously. Being a single mother of 2 is hard enough by itself. I don’t qualify for government assistance so I’m out of pocket a lot of money each day, month, and year. And more years to come. The extra $500 a month needs to be made permanent or at least until we are out of this pandemic. As we speak my daughter is in quarantine because someone in her classroom tested positive so I have to take off work without pay and sit home with her. My job isn’t required to pay me for having to quarantine so the extra on the 15th is a true blessing.\"",
    "state": "Mississippi",
    "imageSqFull": "images/attao5xRnDc2z385d-full.png",
    "imageSqLarge": "images/attao5xRnDc2z385d-large.png"
  },
  {
    "index": 23,
    "articleUrl": "https://www.businessinsider.com/personal-finance/families-affected-child-tax-credit-payments-stopped-2022-3",
    "city": "Memphis",
    "fullName": "Ilea",
    "latitude": 35.114259609721266,
    "longitude": -89.94885577306542,
    "numChildren": "3",
    "quote": "\"I was fortunate during the pandemic I did not lose my job. Learning new tools with virtual learning with 3 children and working from home, there were days I was expected to be in the office because an entire network of people depended on my department. With food and gas prices rising, I started to see the pull in my bank account. I was really glad to see help through the Child Tax Credit monthly payments. Being in Tennessee without my parents or siblings and raising kids, I learned to budget my CTC early on, but the pandemic threw my carefully laid-out plans upside down. When the first CTC payment arrived it allowed me to breath easier, and gave me the financial confidence I needed.\"",
    "state": "Tennessee",
    "imageSqFull": "images/att8ZGLFI6FG0TLTW-full.png",
    "imageSqLarge": "images/att8ZGLFI6FG0TLTW-large.png"
  },
  {
    "index": 24,
    "articleUrl": "",
    "city": "Chester",
    "fullName": "Amber",
    "latitude": 37.34913132596237,
    "longitude": -77.42566207757937,
    "numChildren": "4",
    "quote": "\"We are family of 6, with 4 children and I have a disability from birth. We are both college graduates, but always seemed to be in this middle ground - just above the threshold for help, but not making enough to get out of living paycheck to paycheck and accumulating debt. We had a small savings, but one unexpected expense would easily wipe that out. When the tax credits started, we were still recovering from an electrical house-fire which not only took our home but also my husband's new job because of the amount of time and attention it took away from work. The tax credits helped us get through a very trying time by allowing us to make ends meet with just my disability income.  Now that my husband is back to work, the tax credits have allowed us to get out of the cycle of just barely getting by. We’ve been able to rebuild our emergency fund, pay down debt, pay preschool tuition for our 4 year old, put money away for Christmas presents, and finally save for a down payment on our first home of our own!  Something that seemed so far out of reach now seems possible within the next couple years thanks to having that little extra coming in. If the child tax credit goes away, so does that stability. We go back to being in that gray area of barely getting by but having too much for any additional help.\"",
    "state": "Virginia",
    "imageSqFull": "images/att8PdWTsFwbfzSaF-full.png",
    "imageSqLarge": "images/att8PdWTsFwbfzSaF-large.png"
  },
  {
    "index": 25,
    "articleUrl": "",
    "city": "Atlanta",
    "fullName": "Sabrina",
    "latitude": 33.7898919807355,
    "longitude": -84.4412138659239,
    "numChildren": "2",
    "quote": "\"I am a stay-at-home mom to a 6-year old and a 10-month old. I usually work from home, but that’s very hard to do with a busy infant in the house. And daycare is OUT OF THE QUESTION financially. So my work has become VERY limited. The CTC not only helps me to give my husband a bit of help every month with small household things and gas and such, but I’m also able to put a little portion of it away for my boys’ savings accounts. It has helped my family through the back end of having a baby in the house, and it’s something that now that I know it’s possible to get, I’m wondering why it hasn’t BEEN happening. And I DEFINITELY am pushing for it to continue.\"",
    "state": "Georgia",
    "imageSqFull": "images/attLoQnuRiuM7wjBd-full.png",
    "imageSqLarge": "images/attLoQnuRiuM7wjBd-large.png"
  },
  {
    "index": 26,
    "articleUrl": "",
    "city": "Columbus",
    "fullName": "Von",
    "latitude": 32.51640731810598,
    "longitude": -84.87894063346512,
    "numChildren": "0",
    "quote": "\"CTC is a great stress reduction measure. Knowing that my kids are valued by our society and economy is transformational. The fact that the CTC helps us reduce our debt is a blessing.\"",
    "state": "Georgia",
    "imageSqFull": "images/attgc4S9Tut5e001X-full.png",
    "imageSqLarge": "images/attgc4S9Tut5e001X-large.png"
  },
  {
    "index": 27,
    "articleUrl": "",
    "city": "Los Angeles",
    "fullName": "Melissa",
    "latitude": 33.892770566536086,
    "longitude": -118.39959776120804,
    "numChildren": "3",
    "quote": "\"I don't know what our family would do without the Child Tax Credit. We are budgeted down to our last dollar every month. The CTC allows us to have just that little bit of cushion in case an emergency happens. I really hope it doesn't just stop. I know it's making a difference in my family's life and I'm sure it is helping countless other families.\"",
    "state": "California",
    "imageSqFull": "images/attRA0sVfkaPXpZLC-full.png",
    "imageSqLarge": "images/attRA0sVfkaPXpZLC-large.png"
  },
  {
    "index": 28,
    "articleUrl": "",
    "city": "Madison",
    "fullName": "Noelle",
    "latitude": 34.71192736070064,
    "longitude": -86.74573686913052,
    "numChildren": "2",
    "quote": "\"The pandemic has created a pinch for many families. The Child Tax Credit is a necessary step for mothers and families that desperately depend on reliable and quality childcare in order for them to work and take care of their families.\"",
    "state": "Alabama",
    "imageSqFull": "images/att0HLS2UKGLg0HLS-full.png",
    "imageSqLarge": "images/att0HLS2UKGLg0HLS-large.png"
  },
  {
    "index": 29,
    "articleUrl": "",
    "city": "Clayton",
    "fullName": "Melissa",
    "latitude": 35.653739054318194,
    "longitude": -78.46831262038172,
    "numChildren": "1",
    "quote": "\"The CTC has helped our tiny family in so many ways. We have 1 child with a congenital heart defect and this credit has helped us with the rising costs of gas, groceries, and trips to the cardiologist. My partner and I both work, but, with our child being in the high-risk category during a pandemic, I took a wage cut to work at home for his safety and when the CTC began, it has helped pick up the slack. We did not qualify for any type of assistance due to our income, however, food, gas and basic necessities have skyrocketed in cost and the CTC helps with our budget and obtain what we need without stressing about how we will afford our needs without stretching too thin.\"",
    "state": "North Carolina",
    "imageSqFull": "images/attBK73BcbVwCvXir-full.png",
    "imageSqLarge": "images/attBK73BcbVwCvXir-large.png"
  },
  {
    "index": 30,
    "articleUrl": "https://www.nbcnews.com/nightly-news/video/child-tax-credit-checks-begin-hitting-bank-accounts-116853829738",
    "city": "Conway",
    "fullName": "Sasha",
    "latitude": 35.07757872631861,
    "longitude": -92.46062106306044,
    "numChildren": "1",
    "quote": "\"The gap between the poor and wealthy is widening. We've got billionaires going to space, but we've still got people down here trying to make ends meet. The Child Tax Credit allows for all families, especially single parent households to have basic needs met for the most part.",
    "state": "Arkansas",
    "imageSqFull": "images/att74xyVA5L8rtPuW-full.png",
    "imageSqLarge": "images/att74xyVA5L8rtPuW-large.png"
  },
  {
    "index": 31,
    "articleUrl": "https://cheddar.com/media/without-child-tax-credit-payments-families-forced-to-stretch-financial-resources",
    "city": "Wilmington",
    "fullName": "Colleen",
    "latitude": 39.76749609838799,
    "longitude": -75.52476587860653,
    "numChildren": "2",
    "quote": "\"The Child Tax Credit has allowed me to supplement our income so I can continue to go to grad school.\"",
    "state": "Delaware",
    "imageSqFull": "images/attcnlK8fQeT9hByW-full.png",
    "imageSqLarge": "images/attcnlK8fQeT9hByW-large.png"
  },
  {
    "index": 32,
    "articleUrl": "",
    "city": "Long Beach",
    "fullName": "Jewel",
    "latitude": 33.77958514910425,
    "longitude": -118.16229848772102,
    "numChildren": "1",
    "quote": "\"I’m a caregiver/legal guardian of an intellectual disabled adolescent. Getting the CTC payments has allowed me to purchase an Apple iPad for Iziah so he can listen to music and he can also play educational games...Receiving CTC payment every month will be such a relief especially now we feel the effect of inflation due to the pandemic. It is definitely a stress reliever.\"",
    "state": "California",
    "imageSqFull": "images/attAfvRrjvW006o2g-full.png",
    "imageSqLarge": "images/attAfvRrjvW006o2g-large.png"
  },
  {
    "index": 33,
    "articleUrl": "",
    "city": "Doniphan",
    "fullName": "Christina",
    "latitude": 36.62435503900516,
    "longitude": -90.82123900292284,
    "numChildren": "1",
    "quote": "\"I often find myself crying because I can't afford all our basic essential bills all the time. My child would have everything he needs and more and he would have a mom that's less stressed and depressed. I wish the government knew how it is to be in poverty. Maybe they would have more compassion and offer more help, higher food stamps and just over all more money to pay for basic essential bills we need to survive.\"",
    "state": "Missouri",
    "imageSqFull": "images/attyDDVB3RDHhMio5-full.png",
    "imageSqLarge": "images/attyDDVB3RDHhMio5-large.png"
  },
  {
    "index": 34,
    "articleUrl": "",
    "city": "Sarasota ",
    "fullName": "Denise",
    "latitude": 27.35719645511186,
    "longitude": -82.5377611898874,
    "numChildren": "1",
    "quote": "\"Every month that we have received the monthly child tax credit payment, I was able to use that money to pay for my monthly doctor’s appointment. I have many health conditions and my work health insurance isn’t very good. Instead of this money coming out of my paycheck, I would use the CTC payment so that I would have more spendable money for my child. It keeps me healthy so I can be the best mother I can be. Now that the payments have ended, my appointments will now be funded from my paycheck again.\"",
    "state": "Florida",
    "imageSqFull": "images/attdJTQrFE44up8Gw-full.png",
    "imageSqLarge": "images/attdJTQrFE44up8Gw-large.png"
  },
  {
    "index": 35,
    "articleUrl": "",
    "city": "San Francisco",
    "fullName": "Brooke",
    "latitude": 37.76093630452165,
    "longitude": -122.4358858851879,
    "numChildren": "0",
    "quote": "\"I know from my own childhood with a single mother working full-time, there would have been less trauma and neglect and my whole trajectory in life would have been more productive and prosperous instead of disrupted by clinical depression and intermittent gig employment.\"",
    "state": "California",
    "imageSqFull": "images/attr2mEpeh1rqObbN-full.png",
    "imageSqLarge": "images/attr2mEpeh1rqObbN-large.png"
  },
  {
    "index": 36,
    "articleUrl": "",
    "city": "Millcreek",
    "fullName": "Elisha-Ann",
    "latitude": 40.682165780990466,
    "longitude": -111.86815976573575,
    "numChildren": "1",
    "quote": "\"This child tax credit payment throughout the year has meant I knew I could buy healthy groceries every week to feed my toddler and myself and his father. It's meant I could afford to get the medical care I've put off for over a decade. If this payment ends I go back to hoping I can continue to buy produce each week. If this payment ends I go back to hoping I can pay his sitter on time each week.\"",
    "state": "Utah",
    "imageSqFull": "images/attFu8BuPxSltGyMa-full.png",
    "imageSqLarge": "images/attFu8BuPxSltGyMa-large.png"
  },
  {
    "index": 37,
    "articleUrl": "",
    "city": "The Woodlands",
    "fullName": "Katherine",
    "latitude": 30.19027718133167,
    "longitude": -95.50342552905433,
    "numChildren": "3",
    "quote": "\"2020 was such a rough year for us. We have three children, two who were in day care and one in elementary school/after school care. We tried to hang on as long as we could in hopes that the economy would go back to normal...but it never did. I really didn’t want to give up my career, but in January of 2021 my husband and I decided that for our family to stay afloat, I needed to stay home with our children. Between March 2020 and Jan 2021 we literally LOST money because I was working, paying for our families health benefits and paying for our children to go to day care/after school care. To no fault of our own, our lives have changed in ways we never thought possible. We are now a family of 5 on one income. The $850 we receive monthly for the child tax credit pays almost half of our mortgage, it provides desperately NEEDED financial relief to our family.\"",
    "state": "Texas",
    "imageSqFull": "images/att0Ibn0k8HQAvh7e-full.png",
    "imageSqLarge": "images/att0Ibn0k8HQAvh7e-large.png"
  },
  {
    "index": 38,
    "articleUrl": "",
    "city": "Denver",
    "fullName": "Rebecca",
    "latitude": 39.79091539251182,
    "longitude": -104.77000105338242,
    "numChildren": "3",
    "quote": "\"The child credit has helped enormously, especially with the rise of clothing and hygiene costs. Living expenses are rising high and still keeping families stuck during the pandemic and parents unable to work due to sick children.\"",
    "state": "Colorado",
    "imageSqFull": "images/attddYrie4JKz9XnE-full.png",
    "imageSqLarge": "images/attddYrie4JKz9XnE-large.png"
  },
  {
    "index": 39,
    "articleUrl": "",
    "city": "Okemos",
    "fullName": "Jessie",
    "latitude": 42.71075408918336,
    "longitude": -84.41800663946854,
    "numChildren": "4",
    "quote": "\"Our family is lucky to be at the higher end of eligibility for the tax credit payments. But even with advanced degrees and dual incomes, it is expensive to raise four children! The CTC payments have made a huge impact in our lives over the past 6 months. With our oldest starting college and our youngest son going through treatment for Hodgkin's Lymphoma (he is now cancer-free :)), our already stretched budget was stretched to the max. The CTC allowed us some breathing room and to pay for what the kids needed.\"",
    "state": "Michigan",
    "imageSqFull": "images/attUv3ntnQ22rLgvS-full.png",
    "imageSqLarge": "images/attUv3ntnQ22rLgvS-large.png"
  },
  {
    "index": 40,
    "articleUrl": "",
    "city": "Arvada",
    "fullName": "Lisa",
    "latitude": 39.83779098253138,
    "longitude": -105.11661837129363,
    "numChildren": "1",
    "quote": "\"We are getting $300 a month which is allowing us more flexibility when we look at opportunities for our daughter. It makes childcare more affordable.\"",
    "state": "Colorado",
    "imageSqFull": "images/attSgtOTItVORUROl-full.png",
    "imageSqLarge": "images/attSgtOTItVORUROl-large.png"
  },
  {
    "index": 41,
    "articleUrl": "",
    "city": "DeForest",
    "fullName": "Mike",
    "latitude": 43.244857758679565,
    "longitude": -89.33470661148,
    "numChildren": "2",
    "quote": "\"If we had recurring payments, this would help rebuild our trust in government and give us agency to chart our own courses. Our children would have the stability and economic security that would allow them to focus less on surviving and more on thriving. With some financial cushion, there would be more opportunities open to them as they navigate the choppy waters of the future economy. We would have the reassurance that even if our business fails, we lose our jobs, or have health challenges, we would still have enough to provide for some basic essentials and not go hungry.\"",
    "state": "Wisconsin",
    "imageSqFull": "images/attIUjPpP2ZXXejKA-full.png",
    "imageSqLarge": "images/attIUjPpP2ZXXejKA-large.png"
  },
  {
    "index": 42,
    "articleUrl": "",
    "city": "Olympia",
    "fullName": "April",
    "latitude": 47.03147342111366,
    "longitude": -122.86125480864301,
    "numChildren": "6",
    "quote": "\"We have 3 grown children. The undue stress that they were raised with has been eliminated for our younger children. Knowing that we had some funds coming in for 6 months helped us plan ahead and not struggle so much living paycheck to paycheck.  I've also seen the impact on my adult children who have been able to use the CTC to help afford childcare and the amount of stress it reduces for them, and me as well as grandma who often provides care because they can't afford it full time.\"",
    "state": "Washington",
    "imageSqFull": "images/att3EP1CdWFSRFmRm-full.png",
    "imageSqLarge": "images/att3EP1CdWFSRFmRm-large.png"
  },
  {
    "index": 43,
    "articleUrl": "",
    "city": "Cadillac",
    "fullName": "Mea",
    "latitude": 44.260835848882856,
    "longitude": -85.42244206091952,
    "numChildren": "2",
    "quote": "\"Knowing this was something I could rely on every month would mean being able to have some flexibility when something goes wrong, which eventually it always does. Being able to intervene when a problem starts means stopping it from snowballing out of control.  I know that while it was coming in, I slept a little easier, panicked a little less, and felt a lot less like things were constantly on the edge of falling to pieces.  $500 dollars a month definitely gave me more than just the money itself in value.\"",
    "state": "Michigan",
    "imageSqFull": "images/atthp1B3UXQAPoGgu-full.png",
    "imageSqLarge": "images/atthp1B3UXQAPoGgu-large.png"
  },
  {
    "index": 44,
    "articleUrl": "",
    "city": "Gary",
    "fullName": "Asia",
    "latitude": 41.58801296713038,
    "longitude": -87.37247806238129,
    "numChildren": "5",
    "quote": "\"Minimum wage is $7.25 an hour in Gary Indiana and I only work 2 days a week 3-hr shift since the pandemic and the kids were e-learning... It’s hard for me to afford all my bills and my kids needs, now I’m going to lose my car that I need to get back and forth to work. The child tax credit was the best thing the government has ever done to us and it helped build us back up in every way and it helped me to replace everything that I have lost due to covid-19 and now I no longer have that either I’m so lost right now.\"",
    "state": "Indiana",
    "imageSqFull": "images/att5YaK8Ty76bSxIF-full.png",
    "imageSqLarge": "images/att5YaK8Ty76bSxIF-large.png"
  },
  {
    "index": 45,
    "articleUrl": "",
    "city": "Barrackville",
    "fullName": "Lauren",
    "latitude": 39.500432489051896,
    "longitude": -80.1691772142216,
    "numChildren": "1",
    "quote": "\"Our story may be a little different from most, but it is still important and has helped shape our daughter. My husband and I both have Masters degrees, we could choose from a plethora of jobs, but we choose to have jobs that serve others. As many know human service jobs typically pay less. However, we have enough to meet our needs. With the CTC money, we were able to provide our daughter with better education and supplement it as well. We took her out of family care and put her in an excellent preschool where she has excelled. She is extremely intelligent and family care was a disservice to her intelligence. We knew she was being taken care of but knew a good school would give her so much more. But couldn't afford to do anything about it.\"",
    "state": "West Virginia",
    "imageSqFull": "images/attmxugvgV9BbRWSs-full.png",
    "imageSqLarge": "images/attmxugvgV9BbRWSs-large.png"
  },
  {
    "index": 46,
    "articleUrl": "https://www.businessinsider.com/personal-finance/families-affected-child-tax-credit-payments-stopped-2022-3",
    "city": "Palestine",
    "fullName": "Faye",
    "latitude": 31.751460079229684,
    "longitude": -95.63726938730535,
    "numChildren": "3",
    "quote": "\"Without the CTC payments, we are stressed out by the lack of cash to do simple things like paying for a guitar lesson, getting new pants, or eating at a restaurant while traveling for my cancer treatments.\"",
    "state": "Texas",
    "imageSqFull": "images/attTLzcgj8ZgVtnkj-full.png",
    "imageSqLarge": "images/attTLzcgj8ZgVtnkj-large.png"
  },
  {
    "index": 47,
    "articleUrl": "",
    "city": "Houston",
    "fullName": "Perla",
    "latitude": 29.81361235160374,
    "longitude": -95.2702975602547,
    "numChildren": "3",
    "quote": "\"If we were to receive this payment every month until my kids grew up, this would be an open door to a better future, it will relieve that pressure and worry in life to always try to make ends meet, and my kids will have a chance at a better life and education.\"",
    "state": "Texas",
    "imageSqFull": "images/att7iXedC2o7HDknU-full.png",
    "imageSqLarge": "images/att7iXedC2o7HDknU-large.png"
  },
  {
    "index": 48,
    "articleUrl": "",
    "city": "Hillsboro",
    "fullName": "Jamie",
    "latitude": 45.50538262279465,
    "longitude": -123.02648371598399,
    "numChildren": "5",
    "quote": "\"I am a single mother of 5 kids. I live in a city where rent is sky rocketing. I make $20 an hour and that is considered a great hourly rate. My rent alone is $1890, my monthly take home is $2200. I receive a total of $120 in child support every month as the kids dad only makes $12 an hour and moved out of state.  I can't even afford to pay my electric bill every month let alone gas, food, diapers, toilet paper, car insurance, car payment. There are no open low income rentals available and I have been on a waitlist for all properties for 3 years now. When we were receiving the monthly child tax credits we were able to have our basic necessities paid every month. I can not stress enough the absolute need to have these payments. We are at very close risk of becoming homeless if these payments don't start again.\"",
    "state": "Oregon",
    "imageSqFull": "images/attN5kqLRxrdXSzDC-full.png",
    "imageSqLarge": "images/attN5kqLRxrdXSzDC-large.png"
  },
  {
    "index": 49,
    "articleUrl": "",
    "city": "Oxnard",
    "fullName": "Jason",
    "latitude": 34.21431905758393,
    "longitude": -119.15057125919257,
    "numChildren": "2",
    "quote": "\"We used the money to help pay our rent. My wife's employment was severely impacted by covid. So that money helped keep a roof over our heads. We are struggling to pay our rent and keep food on the table and put gas in our car.\"",
    "state": "California",
    "imageSqFull": "images/attM84CJ9egO9obHz-full.png",
    "imageSqLarge": "images/attM84CJ9egO9obHz-large.png"
  },
  {
    "index": 50,
    "articleUrl": "",
    "city": "Oshkosh",
    "fullName": "Whitney",
    "latitude": 43.98399612177177,
    "longitude": -88.54468994172372,
    "numChildren": "4",
    "quote": "\"At the time we were homeless, it helped us pay for important essentials like diapers food and even back to school shopping. After, it helped with bills as the housing cost went up. We need it back so badly it definitely helped our quality of life.\"",
    "state": "Wisconsin",
    "imageSqFull": "images/att9DiGwwMiECpzC1-full.png",
    "imageSqLarge": "images/att9DiGwwMiECpzC1-large.png"
  },
  {
    "index": 51,
    "articleUrl": "",
    "city": "South Salem",
    "fullName": "Theresa",
    "latitude": 41.263138773999856,
    "longitude": -73.54932171383447,
    "numChildren": "1",
    "quote": "\"When the payments first started, the extra money allowed me some welcomed extra security with paying monthly bills. However, I’m now diagnosed with brain cancer and the chemotherapy, monthly cost of meds and transportation back and forth from treatment have been A LOT more expensive than I thought. The reinstatement of the monthly Child Tax Credit would be actually lifesaving for us. If I, and many other families, could rely on these payments, it would undoubtedly change our lives. I would have the extra security of knowing that all of my medical bills would get paid and my child would have the priceless benefit of a parent who is not overwhelmed.\"",
    "state": "New York",
    "imageSqFull": "images/attYloOvNgferB824-full.png",
    "imageSqLarge": "images/attYloOvNgferB824-large.png"
  },
  {
    "index": 52,
    "articleUrl": "",
    "city": "Hinesville",
    "fullName": "Brittnay",
    "latitude": 31.82376868517964,
    "longitude": -81.61048200711318,
    "numChildren": "1",
    "quote": "\"I was using the child tax credit payments to help with some bills and food to put on the table as my job kept laying people off. I struggle day by day with bills and have been praying for a miracle for weeks now. Gas is too high and food prices are going up and I barely afford regular bills.\"",
    "state": "Georgia",
    "imageSqFull": "images/attlZEb9j7cG71IdB-full.png",
    "imageSqLarge": "images/attlZEb9j7cG71IdB-large.png"
  },
  {
    "index": 53,
    "articleUrl": "",
    "city": "Gwinner",
    "fullName": "Heather",
    "latitude": 46.20802112756588,
    "longitude": -97.66410919837047,
    "numChildren": "2",
    "quote": "\"I have a 13yo & 15yo single mom, whose kids need rides to school, sports, etc. CTC would help me afford a newer used car when ours breaks down. I don’t know what to do or who to talk to about help because EVERYONE is suffering.\"",
    "state": "North Dakota",
    "imageSqFull": "images/attPvP6OkbJmi8JkG-full.png",
    "imageSqLarge": "images/attPvP6OkbJmi8JkG-large.png"
  },
  {
    "index": 54,
    "articleUrl": "",
    "city": "New Orleans ",
    "fullName": "April",
    "latitude": 29.937477238097767,
    "longitude": -90.07135485165551,
    "numChildren": "5",
    "quote": "\"I was able to afford payments for my daughters' braces, gas to get my kids to school, my electric bill, and to buy my children their basic needs. Now that CTC has expired, I’m behind on bills and can barely make it through the month. The impact of receiving monthly payments would get my family further ahead and will give us that extra boost we need to keep us out of poverty.\"",
    "state": "Louisiana",
    "imageSqFull": "images/att1J85gUC2xdAUOQ-full.png",
    "imageSqLarge": "images/att1J85gUC2xdAUOQ-large.png"
  },
  {
    "index": 55,
    "articleUrl": "",
    "city": "Richmond",
    "fullName": "Ana",
    "latitude": 29.56996300130919,
    "longitude": -95.76134029344956,
    "numChildren": "1",
    "quote": "\"[With the CTC] I have been able to pay more money on bills and continue to not struggle to take care of my family. My son's extracurricular activities at school have been able to continue. Can't pay all my bills as I would like and life is more expensive and I'm not able to afford anything. [If the CTC was permanent] I would feel more relief about the cost of living and make sure I provide a stable life for my son.\"",
    "state": "Texas",
    "imageSqFull": "images/attD4CRoiysNSpJTs-full.png",
    "imageSqLarge": "images/attD4CRoiysNSpJTs-large.png"
  },
  {
    "index": 56,
    "articleUrl": "",
    "city": "McKinney ",
    "fullName": "Brooke",
    "latitude": 33.1887613211009,
    "longitude": -96.67368137950217,
    "numChildren": "6",
    "quote": "\"The CTC was a life changer. Coming from a mom who has always struggled paycheck to paycheck, CTC gave my family a better quality of life. CTC helped pay for school clothes and supplies, Christmas, food, but most importantly it helped to pay rent!!! My family moved last year because my husband got a better job, but it’s still not enough to keep us afloat in this economy. I constantly worry about how we are going to make it. We cannot afford our bills and have to cut out basic necessities just to get closer to being able to pay rent. The CTC was a savior and made life comfortable, not having to worry about being able to make it month to month or how bills were going to be paid. We really need to the government to reinstate the CTC to save families like mine.\"",
    "state": "Texas",
    "imageSqFull": "images/attWekwZNQcBCHpxL-full.png",
    "imageSqLarge": "images/attWekwZNQcBCHpxL-large.png"
  },
  {
    "index": 57,
    "articleUrl": "",
    "city": "West Jordan",
    "fullName": "Amy",
    "latitude": 40.58948673965277,
    "longitude": -112.05233576885952,
    "numChildren": "3",
    "quote": "\"I am disabled, my husband is an immigrant resident so he isn't paid well and I'm on SSDI. For once I didn't have to worry about rent which was nice but I was also able to make a small savings account for 2 of my 3 children which is a big deal for a family like us. We have no savings, no vacations, we are house poor with $1958.00 per month rent payment and $800 grocery bills. The CTC payments need to be made permanent so my children can get a hand up out of poverty! And CTC (cut the check) for reparations too!\"",
    "state": "Utah",
    "imageSqFull": "images/attHmUC74JzFF4vY7-full.png",
    "imageSqLarge": "images/attHmUC74JzFF4vY7-large.png"
  },
  {
    "index": 58,
    "articleUrl": "",
    "city": "Graham",
    "fullName": "Denny",
    "latitude": 33.10440525724254,
    "longitude": -98.57624158240523,
    "numChildren": "6",
    "quote": "\"All the little $100 here, $100 there things that my kids couldn't do without really stressing the family like soccer, art camp, youth theater, much less the daycations to museums, etc., that really enriched our lives were possible and stress free, giving us the mental freedom to do them without worrying about stealing from our future or the kids hearing Mom and Dad fight over which kid gets to do what and possibly blaming themselves for family strife and dampening their joy. Now we face a real decision over what gifts do we track our young children into developing and possibly having to limit their, and our society's enrichment because something might cost an extra $20; when you do that times 6, it adds up quickly.  We love seeing our children experience the best versions of themselves and we now trade that so we can keep snacks in the cabinet, or worse, their diets balanced and diapers dry.\"",
    "state": "Texas",
    "imageSqFull": "images/attCB4Ya5Df5ZhqBF-full.png",
    "imageSqLarge": "images/attCB4Ya5Df5ZhqBF-large.png"
  },
  {
    "index": 59,
    "articleUrl": "",
    "city": "Frederick",
    "fullName": "Danielle",
    "latitude": 39.43311566326961,
    "longitude": -77.35296932309858,
    "numChildren": "2",
    "quote": "\"Now that the checks are gone we've had to scale back on medical needs, groceries, water, electric. Medically my daughter is unable to get the necessary surgery she needs because I can't afford the medical devices that she would need afterwards. Having those checks each month would give me a little wiggle room to afford the medical cost each month and keep our electric on. It's very disheartening to know my children are in a situation that can be avoided. Putting children and families in jeopardy of poverty and homelessness is not acceptable when there is a way to avoid it. We need it now more than ever with inflation on the rise. People could use that money to go to work because they could afford daycare. Which in turns gives the economy a boost it needs. Please bring those checks back, help our nation thrive and protect the children that are most vulnerable.\"",
    "state": "Maryland",
    "imageSqFull": "images/attJAKUxgDNzHOIUq-full.png",
    "imageSqLarge": "images/attJAKUxgDNzHOIUq-large.png"
  }
]