/*
  Application Utils

  reusable functionality for this and other map projects
*/

export const createWebEmbedString = url => {
  const webEmbedStr = `<style type="text/css">
.resp-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}

.resp-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
</style>

<div class="resp-container">
  <iframe class="resp-iframe" src="${url}" frameborder="0" scrolling="no"></iframe>
</div>
`
  return webEmbedStr
}
