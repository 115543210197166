/*
  Map Data Utils

  string utilities for the different HTML elements on the page
*/

export const MapTitle = 'Basic Income Stories'
export const MapBlurb =
  'This is a map showing locations of moms who want to share their stories about support for a Basic Income.'

/*
  create content for info Window popup

  example generated content:

  <div class="iw-content">
    <div class="header">
      <img class="img-thumb" src="">
      <div class="info">
        <div class="name">Jane Smith</div>
        <div class="location">Las Vegas, Nevada</div>
        <div class="children">1 Child</div>
      </div>
    </div>
    <div class="line" />
    <div class="quote">I support UBI because...</div>
    <div class="article-link">See article featuring...</div>
  </div>
*/
export const createInfoWindowHtmlString = record => {
  const {
    articleUrl,
    city,
    fullName,
    imageSqLarge,
    numChildren,
    quote,
    state,
  } = record

  // image
  const imageHtml = `<img class="img-thumb" src="${imageSqLarge}">`
  // name
  const nameHtml = `<div class="name">${fullName}</div>`
  // location
  const locationHtml = `<div class="location">${city}, ${state}</div>`
  // number children
  const childUnit = numChildren == '1' ? 'Child' : 'Children'
  const childrenHtml =
    numChildren == '0'
      ? ''
      : `<div class="children">${numChildren} ${childUnit}</div>`

  // info container
  const infoHtml = `<div class="info">${nameHtml}${locationHtml}${childrenHtml}</div>`

  // header container
  const headerHtml = `<div class="header">${imageHtml}${infoHtml}</div>`

  // quote
  const quoteHtml = `<div class="quote">${quote}</div>`

  // article
  const featureStr = `See article featuring ${fullName}`
  const articleUrlHtml =
    articleUrl == ''
      ? ''
      : `<div class="article-link"><a href="${articleUrl}" target="_blank">${featureStr}</a></div>`

  console.log('>> article', record, articleUrl, city, state)

  // html block
  const contentHtml = `<div class="iw-content">${headerHtml}<div class="line"></div>${quoteHtml}${articleUrlHtml}</div>`

  return contentHtml
}

/*
  record is map-data item
*/
export const getMapMarker = record => {
  // add conditional here for different map markers
  return require('@/assets/marker-24px-blue.png')
}

export const mapMarkerSize = 24
