<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// Libs
import throttle from 'lodash/throttle'

export default {
  name: 'App',

  methods: {
    onResize() {
      this.updateWindowDimensions()
    },

    updateWindowDimensions() {
      const windowHeight = window.innerHeight
      const windowWidth = window.innerWidth

      this.$store.commit('setWindowHeight', windowHeight)
      this.$store.commit('setWindowWidth', windowWidth)

      // console.log('resize', windowWidth, windowHeight)
    },
  },

  mounted() {
    this.updateWindowDimensions()
    this.handleonResize = throttle(this.onResize, 250)
    window.addEventListener('resize', this.handleonResize)
  },

  destroyed() {
    window.addEventListener('resize', this.handleonResize)
    this.handleonResize = null
  },
}
</script>

<style lang="scss">
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}
#app {
  font-family: 'GT America', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}
</style>
