<template>
  <div class="info-window" />
</template>

<script>
/*
  Google Maps InfoWindow
  * https://developers.google.com/maps/documentation/javascript/infowindows
*/
// Utils
import { createInfoWindowHtmlString } from '../data/map-data.utils'

export default {
  name: 'InfoWindow',

  props: {
    // data record
    data: {
      type: Object,
      required: false,
    },
    google: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
    // google marker object
    marker: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      infoWindow: null,
    }
  },

  computed: {
    content() {
      const { data } = this

      if (data === null) {
        return ''
      } else {
        return createInfoWindowHtmlString(data)
      }
    },
  },

  methods: {
    _closeInfoWindow() {
      this.infoWindow.close()
    },

    _openInfoWindow() {
      const { content, map, marker } = this

      this.infoWindow.setContent(content)
      this.infoWindow.open(map, marker)
    },

    _handleCloseEvent() {
      this.$emit('closed')
    },
  },

  watch: {
    marker(newVal, oldVal) {
      if (newVal == null) {
        this._closeInfoWindow()
      } else {
        this._openInfoWindow()
      }
    },
  },

  mounted() {
    const { google } = this

    const iw = new google.maps.InfoWindow({
      content: this.blurb,
    })
    google.maps.event.addListener(iw, 'closeclick', this._handleCloseEvent)

    this.infoWindow = iw
  },
}
</script>

<style lang="scss">
.iw-content {
  // needs specific width or else popup is too wide
  width: 260px;

  .line {
    border-bottom: 1px solid grey;
    margin: 8px 0;
  }

  .article-link {
    margin-top: 10px;
  }

  .img-thumb {
    width: 35%;
    height: 35%;
    margin-right: 10px;
    border: 1px solid grey;
  }

  .info {
    display: flex;
    flex-direction: column;

    .name {
      font-weight: bold;
      line-height: 24px;
      font-size: 20px;
      margin-bottom: 6px;
    }

    .location,
    .children {
      margin-bottom: 4px;
    }
  }

  .header {
    display: flex;
  }

  .button-wrap {
    margin: 16px 0 12px 0;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .button {
      width: 120px;
      height: 36px;
      border: none;
      font-weight: bold;
    }

    .button-left {
      color: white;
      background-color: #ccc;
      margin-right: 20px;
    }
    .button-left.mgi {
      background-color: #4d0487;
    }
    .button-left.non-mgi {
      background-color: #00a79d;
    }

    .button-right {
      color: white;
      background-color: grey;
    }
  }
}

/*
  mobile media query
*/
@media only screen and (max-width: 600px) {
  .iw-content {
    width: 100%;

    .button-wrap {
      flex-direction: column;

      .button {
        // update dimensions, better for mobile
        width: 140px;
        height: 42px;
      }

      .button-left {
        margin: 0 0 12px 0; // remove space between buttons
      }
    }
  }
}
</style>
