/*
  Google Map Marker
  * https://developers.google.com/maps/documentation/javascript/markers
*/

// Libs
import Vue from 'vue'
import { getMapMarker, mapMarkerSize } from '../data/map-data.utils'

const SelectedEventName = 'selected'

const MapMarker = Vue.extend({
  props: {
    data: {
      type: Object,
      required: true,
    },
    google: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      marker: null, // reference to google Marker instance
    }
  },

  computed: {
    position() {
      const { data } = this
      return {
        lat: data.latitude,
        lng: data.longitude,
      }
    },
  },

  methods: {
    setSelectedListener(callback) {
      this.$on(SelectedEventName, callback)
    },

    removeSelectedListener(callback) {
      this.$on(SelectedEventName, callback)
    },

    _drawIcon() {
      const { google, map, position } = this
      const { data } = this
      const halfW = mapMarkerSize / 2

      const iconUrl = getMapMarker(data)
      var marker = new google.maps.Marker({
        map,
        position,
        icon: {
          url: iconUrl,
          // offset map marker, 1/2 of width
          anchor: new google.maps.Point(halfW, halfW),
        },
      })
      marker.addListener('click', this._handleClick)

      this.marker = marker
    },

    _handleClick() {
      const evtData = { data: this.data, marker: this.marker }
      this.$emit(SelectedEventName, evtData)
    },

    _initialize() {
      this._drawIcon()
    },
  },

  created() {
    this._initialize()
  },
})

export const createMarker = function(google, map, data) {
  const obj = new MapMarker({
    propsData: {
      google,
      map,
      data,
    },
  })
  return obj
}
