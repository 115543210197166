<template>
  <div id="map-view">
    <template v-if="shouldRender">
      <GoogleMapLoader
        :apiKey="apiKey"
        :mapConfig="mapConfig"
        :mapIds="mapIds"
        @loaded="_handleMapLoaded"
      >
        <template slot-scope="{ google, map }">
          <InfoWindow
            :data="selectedData"
            :google="google"
            :map="map"
            :marker="selectedMarker"
            @closed="_handleInfoWindowClose"
          />
        </template>
      </GoogleMapLoader>
    </template>
  </div>
</template>

<script>
// Libs
import { MarkerClusterer } from '@googlemaps/markerclusterer'

// Constants & Data
import {
  CenterUSAGps,
  DeviceBreakPoints,
  MapCoordinatesOffset,
  MapZoom,
} from '../app.constants'
import { mapRecords } from '../data/map-data'

// Components
import InfoWindow from './InfoWindow.vue'
import GoogleMapLoader from './GoogleMapLoader.vue'

// Models
import { createMarker } from './map-marker.model'

// hashed by index
const gMapMarkerObjs = {}

export default {
  name: 'MapView',

  props: {
    windowWidth: {
      type: Number,
      required: true,
    },
  },

  components: {
    GoogleMapLoader,
    InfoWindow,
  },

  data() {
    return {
      apiKey: process.env.VUE_APP_API_KEY,
      mapIds: [process.env.VUE_APP_MAP_ID],
      mapRecords,

      selectedData: null,
      selectedMarker: null,

      // google map refs
      google: null,
      map: null,
      clusterer: null,
    }
  },

  computed: {
    shouldRender() {
      const { windowWidth } = this
      return windowWidth > 0
    },

    /*
      example:

      mapConfig: {
        center: CenterUSAGps,
        fullscreenControl: false,
        mapId: process.env.VUE_APP_MAP_ID,
        mapTypeControl: false,
        streetViewControl: false,
        zoom: 2.5,
      },
    */
    mapConfig() {
      const { mapZoom } = this

      return {
        center: CenterUSAGps,
        fullscreenControl: false,
        mapId: process.env.VUE_APP_MAP_ID,
        mapTypeControl: false,
        streetViewControl: false,
        zoom: mapZoom,
      }
    },

    mapZoom() {
      const { windowWidth } = this
      const { mobile: mBP, tablet: tBP, desktop: dBP } = DeviceBreakPoints
      let zoom = MapZoom.desktop

      if (windowWidth <= mBP) {
        zoom = MapZoom.mobile
      } else if (windowWidth <= tBP) {
        zoom = MapZoom.tablet
      } else {
        zoom = MapZoom.desktop
      }
      return zoom
    },
  },

  methods: {
    _createMapCluster() {
      const { map } = this

      // get "raw" map markers from Vue objects
      const markers = Object.values(gMapMarkerObjs).map(item => item.marker)

      const markerCluster = new MarkerClusterer({
        map,
        markers,
        onClusterClick: this._handleClusterClick,
      })
      this.cluster = markerCluster
    },

    _createMapMarkers() {
      const { mapRecords, map, google } = this

      mapRecords.forEach((rec, idx) => {
        const marker = createMarker(google, map, rec)
        marker.setSelectedListener(this._handleMarkerClick)
        gMapMarkerObjs[idx] = marker
      })
    },

    _initializeMap() {
      this._createMapMarkers()
      this._createMapCluster()
    },

    // _ is MapMouseEvent
    _handleClusterClick(_, cluster, map) {
      const { markers } = cluster

      // close current info window
      this._resetData()

      // init bounds with current markers
      const bounds = new google.maps.LatLngBounds()
      for (var idx in markers) {
        const latlng = markers[idx].getPosition()
        bounds.extend(latlng)
      }

      // extend bounds coords by offset
      const extendNE = new google.maps.LatLng(
        bounds.getNorthEast().lat() + MapCoordinatesOffset,
        bounds.getNorthEast().lng() + MapCoordinatesOffset,
      )
      const extendSW = new google.maps.LatLng(
        bounds.getSouthWest().lat() - MapCoordinatesOffset,
        bounds.getSouthWest().lng() - MapCoordinatesOffset,
      )
      bounds.extend(extendNE)
      bounds.extend(extendSW)

      // adjust map
      map.fitBounds(bounds)
    },

    _handleInfoWindowClose(_) {
      this._resetData()
    },

    _handleMarkerClick(evt) {
      const { data, marker } = evt

      this.selectedData = data
      this.selectedMarker = marker
    },

    _handleMapLoaded(evt) {
      // sanity check
      if (this.google !== null || this.map !== null) {
        console.warn('map was reloaded')
      }

      // save incoming event data
      this.google = evt.google
      this.map = evt.map

      // initialize /after/ data is saved
      this._initializeMap()
    },

    _resetData() {
      this.selectedData = null
      this.selectedMarker = null
    },
  },
}
</script>

<style lang="scss">
#map-view {
  height: 100%;
}
</style>
