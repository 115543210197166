<template>
  <div id="embed">
    <MapView :windowWidth="windowWidth" />
  </div>
</template>

<script>
import MapView from '../components/MapView.vue'

export default {
  name: 'Embed',

  components: {
    MapView,
  },

  computed: {
    windowWidth() {
      return this.$store.getters['windowWidth']
    },
  },
}
</script>

<style lang="scss">
#embed {
  height: 100%;
}
</style>
