<template>
  <div id="home">
    <div id="header" ref="header">
      <div class="logo" />
      <div class="site-title">Basic Income Stories</div>
    </div>
    <div class="map-blurb" ref="blurb">{{ MapBlurb }}</div>
    <div class="map-container" :style="mapContainerStyles">
      <MapView :windowWidth="windowWidth" />
    </div>
    <div id="footer" ref="footer">
      <div class="attribution">
        Created with <span class="heart-icon">love</span> by
        <a class="white" href="https://incomemovement.org">Income Movement</a>
      </div>
      <!-- <div class="embed-btn" @click="_handleEmbedClick">Embed this map</div> -->
    </div>
  </div>
</template>

<script>
// Components
import MapView from '../components/MapView.vue'

// Contants
import { EmbedUrl } from '../app.constants'

// Utils
import { createWebEmbedString } from '../app.utils'
import { MapBlurb } from '../data/map-data.utils'

export default {
  name: 'Home',

  data() {
    return {
      MapBlurb: MapBlurb,
      mapHeight: 0,
    }
  },

  components: {
    MapView,
  },

  computed: {
    mapContainerStyles() {
      const { mapHeight } = this
      const s = {
        height: `${mapHeight}px`,
      }
      return s
    },

    windowHeight() {
      return this.$store.getters['windowHeight']
    },

    windowWidth() {
      return this.$store.getters['windowWidth']
    },
  },

  methods: {
    _handleEmbedClick() {
      const str = createWebEmbedString(EmbedUrl)
      // TODO: put this into a popup
      console.log(str)
    },

    getBlurbHeight() {
      const el = this.$refs.blurb
      return el ? el.clientHeight : 0
    },

    getFooterHeight() {
      const el = this.$refs.footer
      return el ? el.clientHeight : 0
    },

    getHeaderHeight() {
      const el = this.$refs.header
      return el ? el.clientHeight : 0
    },

    calcMapHeight() {
      const { windowHeight } = this
      const hBlurb = this.getBlurbHeight()
      const hFooter = this.getFooterHeight()
      const hHeader = this.getHeaderHeight()

      this.mapHeight = windowHeight - (hBlurb + hFooter + hHeader)
    },
  },

  watch: {
    windowWidth() {
      this.calcMapHeight()
    },
    windowHeight() {
      this.calcMapHeight()
    },
  },
}
</script>

<style lang="scss">
$footerPadding: 10px;
$incomeMovementGreen: #18afa7;

#app {
  background-color: #eee;
}

#home {
  // position: relative;
  height: 100%;

  #header {
    width: 100%;
    background-color: $incomeMovementGreen;

    color: white;
    padding: 15px 0;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .logo {
      background-image: url('~@/assets/logo.png');
      background-repeat: no-repeat;
      background-size: contain;
      width: 40px;
      height: 40px;
      margin-left: 15px;
    }
    .site-title {
      font-size: 26px;
      font-weight: bold;
      margin-left: 10px;
    }
  }

  .map-blurb {
    padding: 10px;
  }
  .map-container {
    // height: dynamically set
    width: 100%;
  }

  #footer {
    padding: $footerPadding;
    background-color: $incomeMovementGreen;

    display: flex;
    flex-direction: row;

    a.white {
      color: white;
    }

    .attribution {
      color: white;

      .heart-icon {
        display: inline-block;
        width: 13px;
        background-image: url('~@/assets/heart-icon.svg');
        background-position: 50% 50%;
        background-repeat: no-repeat;
        color: transparent;
      }
    }

    .embed-btn {
      height: 26px;
      width: 140px;
      padding: 10px;

      color: white;
      text-align: center;
      background-color: black;
    }
  }
}
</style>
