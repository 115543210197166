// Libs
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

/*
  setup main store
*/

export const store = new Vuex.Store({
  state: {
    windowDims: {
      width: 0,
      height: 0,
    },
  },

  getters: {
    windowHeight(state) {
      const { windowDims } = state
      return windowDims.height
    },

    windowWidth(state) {
      const { windowDims } = state
      return windowDims.width
    },
  },

  mutations: {
    setWindowHeight(state, payload) {
      const { windowDims } = state
      windowDims.height = payload
    },

    setWindowWidth(state, payload) {
      const { windowDims } = state
      windowDims.width = payload
    },
  },
})
